import { ready } from "./utils.js";

ready(() => {
  const header = document.getElementsByTagName("header")[0];

  if (header) {
    document.addEventListener("scroll", function () {
      const scrollY = window.scrollY;
      if (scrollY > 150) {
        header.classList.add("small");
      } else if (scrollY < 10) {
        header.classList.remove("small");
      }
    });
  }
});

